import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderComp2";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import Seo from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/placeholder.png';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

// import "../scss/main.scss";

export default function Story2024() {
  useEffect(() => {
    function checkUTMParameters() {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var utmSource = urlParams.get("utm_source");
      var utmMedium = urlParams.get("utm_medium");

      // Check if both utm_source and utm_medium are present and have the correct values
      if (utmSource === "specific_source" && utmMedium === "referrer") {
        // Redirect the user to the desired page only if the redirect flag is not set
        if (!sessionStorage.getItem("redirected")) {
          sessionStorage.setItem("redirected", "true");
          navigate("/submit-story-2024-pp/");
        }
      }
    }

    // Call the function when the DOM is ready
    checkUTMParameters();
  }, []);

  return (
    <>{ /* <SEO title="Welcome" keywords={[`great`, `british`, `dogs`]} /> */ }
    <SEO
      title="greatbritishdogs.co.uk | Submit story 2024"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div css={css`
                background-color: #004030 !important;
                min-height: calc(100vh - 84px) !important;
                `}>
            <div css={css`
                width: 100% !important; max-width: 1060px !important; padding-top: 50px !important; padding-right: 1rem !important; padding-left: 1rem !important; margin: 0 auto !important;`}>
            <h1 css={css`
                display: none !important;
                `}>
              Submit your dog story for a chance to win
            </h1>
            <h2 css={css`
                display: none !important;
                `}>Tell us what makes your dog great for a chance to be featured and to win an incredible pooch-themed prize</h2>
            <div class="flex">
                <iframe src="https://www.greatbritishdogs.co.uk/winalot-2024-comp/" class="iframe-adjuster"></iframe>
            </div>
            <br /><br />
          </div>
      </div>
      </>
    );
}